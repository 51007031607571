.btnContainer {
    text-align: center;
    width: 100%;
    float: left;
}

.btnSend {
    color: #ffffff !important;
    background-color: #464872 !important;
    top: 15px;
    width: 100%;
    height: 35px;
    font-size: 16px !important;
    margin-bottom: 30px !important;
    text-transform: none !important;
}

@media (min-width: 720px) {
    .btnSend {
        width: 340px;
    }
}

.checkUserBtn {
    position: absolute !important;
    right: 0 !important;
    top: 11px !important;
    height: 55px !important;
    background-color: #464872 !important;
    text-transform: none !important;
}

.checkUserBtn:hover {
    background-color: #464872 !important;
}

.coplementaryText {
    text-align: center;
    width: 85%;
    font-size: 24px;
    color: #4a4a4a;
    margin: 20px auto;
}

.loginForm {
    width: 90%;
    margin: 0 auto;
    position: relative;
}

.recoverAccountBtn {
    color: #464872 !important;
    font-size: 14px !important;
    text-transform: none !important;
}

.recoverAccountBtn:hover {
    background-color: transparent !important;
}

.textField {
    width: 100%;
    margin: 10px 0;
}