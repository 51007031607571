.iconSection {
    display: block;
    font-size: 50px !important;
    position: absolute;
    margin-top: -30px;
}

.section {
    width: 30% !important;
    background-color: transparent !important;
    text-transform: none !important;
    height: 150px !important;
    margin: -10px 1.5% !important;
    color: #00B2E3 !important;
    position: relative;
    border-radius: 0 !important;
}

.section:hover {
    width: 30% !important;
    background-color: #00B2E3 !important;
    text-transform: none !important;
    height: 150px !important;
    margin: -10px 1.5% !important;
    color: #fff !important;
    position: relative;
    border-radius: 0 !important;
}

.sectionName {
    display: block;
    font-size: 16px;
    margin-top: 70px;
    text-align: center;
    line-height: 20px;
}

@media (min-width: 720px) {
    .sectionName {
        font-size: 18px;
    }
}

.sectionSelected {
    width: 30% !important;
    background-color: #00B2E3 !important;
    text-transform: none !important;
    height: 150px !important;
    margin: -10px 1.5% !important;
    color: #fff !important;
    position: relative;
    border-radius: 0 !important;
}

.sectionUser {
    width: 47% !important;
    background-color: transparent !important;
    text-transform: none !important;
    height: 150px !important;
    margin: -10px 1.5% !important;
    color: #00B2E3 !important;
    position: relative;
    border-radius: 0 !important;
}

.sectionUser:hover {
    width: 47% !important;
    background-color: #00B2E3 !important;
    text-transform: none !important;
    height: 150px !important;
    margin: -10px 1.5% !important;
    color: #fff !important;
    position: relative;
    border-radius: 0 !important;
}

.sectionUserSelected {
    width: 47% !important;
    background-color: #00B2E3 !important;
    text-transform: none !important;
    height: 150px !important;
    margin: -10px 1.5% !important;
    color: #fff !important;
    position: relative;
    border-radius: 0 !important;
}

.sectionsConatiner {
    width: 90%;
    margin: 0 auto;
    max-width: 980px;
}

@media (min-width: 720px) {
    .sectionsConatinerUser {
        width: 80%;
        margin: 0 10%;
    }
}

.sectionsConatinerUser {
    width: 90%;
    margin: 0 auto;
    max-width: 980px;
}

@media (min-width: 720px) {
    .sectionsConatinerUser {
        width: 50%;
        margin: 0 25%;
    }
}

.navConatiner {
    width: 100%;
    height: 130px;
    background-color: #f0f0f0;
    margin-top: 15px;
}