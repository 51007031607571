.itemSelected {
    text-transform: none !important;
    color: #00B2E3 !important;
}

.myTicketsContainer {
    margin-bottom: 30px;
}

.myTicketsTitle {
    font-size: 18px;
    font-weight: bold;
}

.tab {
    text-transform: none !important;
    color: #000 !important;
}

.ticket {
    list-style: none;
}

.ticketLink {
    text-transform: none !important;
    color: #45C3DB !important;
    font-size: 17px;
    text-decoration: underline !important;
}

.ticketLink:hover {
    background-color: transparent !important;
}

.ticketsList {
    padding-left: 10px;
}