.description {
    text-align: center;
    width: 90%;
    margin: 20px auto;
    font-size: 18px;
    line-height: 30px;
}

.homeContainer {
    width: 90%;
    margin: 0 auto;
    min-height: 60vh;
    max-width: 980px;
}

@media (min-width: 720px) {
    .homeContainer {
        width: 80%;
    }
}

.ticket {
    list-style: none;
}

.ticketLink {
    text-transform: none !important;
    color: #45C3DB !important;
    font-size: 17px;
    text-decoration: underline !important;
}

.ticketLink:hover {
    background-color: transparent !important;
}

.title {
    font-size: 50px;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    line-height: 50px;
}

.yourTickets {
    font-size: 18px;
}