.header {
    background: #FFFFFF;
    box-shadow: none !important;
    min-height: 44px !important;
    max-height: 44px;
}

.headerContainer {
    z-index: 9;
    position: relative !important;
}

.mayahiiImage {
    width: 150px;
    height: 35px;
    margin: 0;
    position: absolute;
    left: 20px;
    float: left;
}

@media (min-width: 720px) {
    .mayahiiImage {
        left: 100px;
    }
}

.img {
    width: 100%;
    height: 100%;
}