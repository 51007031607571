.agreeBtn {
    position: relative !important;
    margin: 0px 20px !important;
    background: #00B2E3 !important;
    color: #fff !important;
    font-size: 15px !important;
    text-transform: none !important;
    font-weight: 500 !important;
    width: 210px !important;
    height: 40px !important;
}

.agreeBtn:disabled {
    position: relative !important;
    margin: 0px 20px !important;
    background: #9d767b !important;
    color: #fff !important;
    font-size: 15px !important;
    text-transform: none !important;
    font-weight: 500 !important;
    width: 210px !important;
    height: 40px !important;
}

.btnsContainer {
    text-align: center;
    margin-bottom: 20px;
}

.cancelBtn {
    position: relative !important;
    margin: 0px 20px !important;
    background: #80797a !important;
    color: #fff !important;
    font-size: 15px !important;
    text-transform: none !important;
    font-weight: 500 !important;
    width: 210px !important;
    height: 40px !important;
}

.item {
    margin-bottom: 20px;
}

.listContainer {
    font-family: 'Roboto', sans-serif;
    width: 65%;
    margin: 20px auto 0;
}

.subTitle {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    width: 60%;
    margin: 0 auto;
    font-weight: bold;
}

.text {
    font-family: 'Roboto', sans-serif;
    margin-left: 10px;
    font-size: 18px;
}

.titleSection {
    text-align: center;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
}