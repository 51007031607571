.formContainerLogin {
    height: auto;
    padding: 20px;
    position: relative;
    width: 90%;
    box-shadow: 0px 6px 10px 0px rgba(99, 77, 77, 0.5);
    background-color: #ffffff;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 100px;
}

@media (min-width: 720px) {
    .formContainerLogin {
        width: 700px;
        margin-top: 45px;
        margin-left: auto;
    }
}

@media (min-width: 1220px) {
    .formContainerLogin {
        margin-left: 240px;
    }
}

.formContainerRegister {
    height: auto;
    padding: 20px;
    position: relative;
    width: 90%;
    box-shadow: 0px 6px 10px 0px rgba(99, 77, 77, 0.5);
    background-color: #ffffff;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
}

@media (min-width: 720px) {
    .formContainerRegister {
        width: 460px;
        margin-top: 45px;
    }
}

@media (min-width: 980px) {
    .formContainerRegister {
        margin-left: 40px;
    }
}

@media (min-width: 1440px) {
    .formContainerRegister {
        margin-left: 140px;
    }
}

.formSection {
    background-image: url('https://d1g6c01s9ssd7h.cloudfront.net/img/accessBanner.jpg');
    width: 100%;
    height: calc(100vh - 90px);
    padding-top: 1px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    font-family: 'DM Sans', sans-serif;
}

.formSectionRegister {
    background-image: url('https://d1g6c01s9ssd7h.cloudfront.net/img/accessBanner.jpg');
    width: 100%;
    height: 100%;
    padding-top: 1px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    position: relative;
    top: -6px;
    font-family: 'DM Sans', sans-serif;
    padding-bottom: 40px;
}

.sendOtherOptionBtn {
    color: #a41034 !important;
    font-size: 13px !important;
    text-align: center;
    letter-spacing: 0.43px !important;
    display: block !important;
}

.sendOtherOptionBtn:hover {
    background-color: transparent !important;
}

.subtitle {
    color: #3b3838;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.43px;
    margin-bottom: 0.35em;
    font-family: 'Roboto', sans-serif;
    line-height: 1.71429em;
    text-align: center;
}

.title {
    font-family: 'Roboto', sans-serif;
    color: #4a4a4a;
    font-size: 24px;
    text-align: center;
    margin: 0;
    letter-spacing: 1.35px;
}

