.buttonSave {
    background-color: transparent !important;
    text-transform: none !important;
    width: 100%;
    height: 30px;
    box-shadow: none !important;
    color: #000 !important;
    font-size: 18px !important;
    text-align: left !important;
}

.buttonSaveHover {
    background-color: #890f20 !important;
}

.containerButtons {
    width: 100%;
    border-top-width: 1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 2px dashed rgba(0, 0, 0, 0.2);
    border-top-width: 2px;
    padding: 8px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.05);
    color: #999;
    margin-top: -7px;
}

.contentTextfield {
    margin: 3%;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentTextMessage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropzone {
    border: none;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 5px 0;
}

.dropzoneError {
    cursor: pointer;
    width: 100%;
    background-color: #eeeeee;
    border: 1px dashed #890f20;
    border-radius: 5px;
}

.descriptionActivity {
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    color: #000;
}

.dropzoneContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fileName {
    width: 100%;
}

.iconValidationCorrect {
    color: #45BBB3;
    margin-left: 5%;
}

.iconValidationIncorrect {
    color: #848484;
    border-radius: 50%;
    border: 2px solid #848484;
    font-size: 16;
    margin-left: 5%;
}

.inputPdf {
    flex: 1;
    font-size: 14px !important;
    color: #000;
    cursor: text;
    display: block;
    padding-bottom: 4px !important;
    background-color: #d2d2d2;
    border: 0;
    font: inherit;
    list-style: none;
    margin: 0;
    outline: 0;
    overflow: visible;
    padding: 0;
    width: 100%;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    padding: 8px 0 15px 0;
}

.numberImg {
    width: 30px;
    height: 30px;
}

.stepNumber {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textMessage {
    color: #890f20;
    font-size: 14px;
    font-weight: bold;
}

.titleActivity {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #000;
}

.messageDz {
    text-align: center;
}

.messageErrorDz {
    text-align: center;
    color: #890f20;
}