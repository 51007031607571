.checkTicketContainer {
    width: 80%;
    margin: 0 auto;
    color: #333;
}

.input {
    background-color: #fff;
    width: 100%;
}

.inputsContainer {
    padding: 19px;
    margin: 20px auto 50px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    width: 100%;
}

@media (min-width: 730px) {
    .inputsContainer {
        width: 430px;
    }
}

.sendBtn {
    background-color: #3f9c70 !important;
    text-transform: none !important;
    border-color: 2px solid #307856 !important;
    color: #fff !important;
    width: 100%;
    margin: 20px auto 30px !important;
    font-size: 18px !important;
}

.sendBtn:disabled {
    background-color: #719f8b !important;
    border-color: 2px solid #307856 !important;
}

.text {
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
}

.title {
    font-size: 40px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
    line-height: 45px;
}

@media (min-width: 980px) {
    .titleText {
        font-size: 50px;
        margin-bottom: 0;
    }
}