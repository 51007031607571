.btnContainer {
    width: 70%;
    overflow: hidden;
    text-align: right;
    padding-top: 30px;
    margin: 0 auto;
}

.btnContainerNoUser {
    width: 100%;
    overflow: hidden;
    text-align: right;
    padding-top: 30px;
    margin: 0 auto;
}

.createTicket {
    width: 100%;
    float: left;
    border-bottom: 1px solid #eee;
    padding-bottom: 40px;
}

@media (min-width: 980px) {
    .userInfo {
        width: 65%;
    }
}

.createTicketComplete {
    width: 70%;
    float: left;
    border-bottom: 1px solid #eee;
    padding-bottom: 40px;
    margin: 0 15%;
}

.createTicketBtn {
    text-transform: none !important;
    background: #3f9c70 !important;
    border-color: #307856 !important;
    color: #fff !important;
}

.createTicketBtn:disabled {
    background: #7c998b !important;
}

.editorContainer {
    border: 1px solid #eee;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 10px;
    min-height: 220px;
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-top: -1px;
}

.infoTitle {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
}

.infoText {
    color: #333;
    font-size: 18px;
    margin-top: 0;
}

.input {
    width: 100%;
}

.inputSelect {
    width: 100% !important;
    margin: 30px 0 10px !important;
}

@media (min-width: 980px) {
    .inputSelect {
        width: 50% !important;
    }
}

.newTicketContainer {
    width: 80%;
    margin: 0 auto;
}

.subTitle {
    margin: 0 0 15px;
    font-size: 18px;
    line-height: 1.72222;
}

.titleSection {
    border-bottom: 1px solid #eee;
    text-align: center;
}

.titleText {
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 45px;
    text-align: center;
}

@media (min-width: 980px) {
    .titleText {
        font-size: 50px;
        margin-bottom: 0;
    }
}

.userInfo {
    width: 100%;
    float: left;
}

@media (min-width: 980px) {
    .userInfo {
        width: 33%;
        margin-right: 2%;
    }
}