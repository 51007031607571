.img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.userAvatar {
    width: 59px;
    float: left;
    height: 59px;
    margin: 0;
}

.userEmail {
    top: 70px;
    width: 100%;
    position: absolute;
    font-size: 10px;
}

.userInfo {
    height: 100px;
}

.userName {
    float: left;
    position: relative;
    font-size: 16px;
    margin-left: 10px;
    font-weight: bold;
}