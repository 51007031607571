.helpBtn {
    float: left;
    color: #000000;
}

.helpIcon {
    color: #000000;
}

.img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.noUserIcon {
    color: #000000;
}

.userIcon {
    width: 24px;
    height: 24px;
    margin: 0;
    float: left;
}