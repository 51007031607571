.accordionCategoryContainer {
    background: #e5f5ed !important;
    border-color: #3f9c70 !important;
    color: #3f9c70 !important;
    font-size: 18px !important;
    font-weight: bold !important;
}

.accordionQuestionContainer {
    background: #fff !important;
    border-color: #b5e2ee !important;
    color: #00B2E3 !important;
    font-size: 18px !important;
    font-weight: normal !important;
}

.accordionTitle {
    margin: 0;
}

.faqTitle {
    font-size: 18px;
    font-weight: bold;
}